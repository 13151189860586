import React from "react";

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
      hours: "",
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    const timeZoneInit = (new Date().getTimezoneOffset() / 60) * -3600; //get local timezone
    let timestamp = new Date().getTime(); //universal ?
    const d = new Date(timestamp + (-timeZoneInit + this.props.time) * 1000);
    this.setState({
      hours: `${d.getHours()} : ${
        d.getMinutes() < 10 ? "0" : ""
      }${d.getMinutes()}`,
      //   time: new Date().toLocaleString()
    });
  }
  render() {
    return <div className="time-hours">{this.state.hours}</div>;
  }
}

export default Clock;
