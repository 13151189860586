import React, { useEffect, useRef, useState } from "react";
import Clock from "./components/clock";
const api = {
  key: "689f48578ace0779e7a22e261042e17a",
  base: "https://api.openweathermap.org/data/2.5/",
};

function App() {
  React.useEffect(() => {
    let lat;
    let lon;

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const crd = pos.coords;
        lat = crd.latitude;
        lon = crd.longitude;
        fetch(
          `${api.base}weather?lat=${lat}&lon=${lon}&units=metric&appid=${api.key}`
        )
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            setWeather(result);
            setQuery("");
            document.title = `${result.name}, ${
              result.sys.country
            } - ${Math.round(result.main.temp)}°c`;
            const favicon = getFaviconEl(); // Accessing favicon element
            console.log(result.weather[0].main.toLowerCase());
            favicon.href = `./icons/${result.weather[0].main.toLowerCase()}.png`;

            // `./icons/${result.weather[0].description
            //   .toLowerCase()
            //   .split(" ")
            //   .join("-")}.png`
          });
      },
      (err) => {
        console.warn(`ERREUR (${err.code}): ${err.message}`);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }, []);

  const [query, setQuery] = useState("");
  const [weather, setWeather] = useState({});
  let coloredIcons = true;
  let i = 0;
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  const search = (evt) => {
    if (evt.key === "Enter") {
      fetch(`${api.base}weather?q=${query}&units=metric&appid=${api.key}`)
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          setWeather(result);
          setQuery("");
          document.title = `${result.name}, ${
            result.sys.country
          } - ${Math.round(result.main.temp)}°c`;
          const favicon = getFaviconEl(); // Accessing favicon element
          console.log(result.weather[0].main.toLowerCase());
          favicon.href = `./icons/${result.weather[0].main.toLowerCase()}.png`;
        });
    }
  };
  const dateBuilder = (d) => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();
    return `${day} ${date} ${month} ${year}`;
  };
  const sunsetBuilder = (timestamp, timeZone) => {
    //7200 is our time Zone in france
    let date = new Date((timestamp - 7200 + timeZone) * 1000);
    return `${date.getHours()}h${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;
  };

  return (
    <div className="app">
      <main>
        <div>
          <div className="location-box">
            <div className="search-box">
              <div className="search-icon"></div>
              <input
                type="text"
                className="search-bar"
                placeholder={
                  typeof weather.main != "undefined"
                    ? `${weather.name}, ${weather.sys.country}`
                    : "Search..."
                }
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                onKeyPress={search}
              />
            </div>
            <div className="location">
              {typeof weather.main != "undefined"
                ? `${weather.name}, ${weather.sys.country}`
                : "City"}
            </div>
            {typeof weather.main != "undefined" ? (
              <Clock time={weather.timezone} />
            ) : (
              <Clock time={7200} />
            )}
          </div>
          <div className="weather-box">
            <div
              className={
                typeof weather.main != "undefined"
                  ? `weather-icon ${
                      coloredIcons
                        ? `${weather.weather[0].main.toLowerCase()}-color ${weather.weather[0].description
                            .toLowerCase()
                            .split(" ")
                            .join("-")}-color`
                        : (weather.weather[0].main.toLowerCase(),
                          weather.weather[0].description
                            .toLowerCase()
                            .split(" ")
                            .join("-"))
                    }`
                  : "weather-icon search"
              }
            ></div>
            <div className="weather">
              {typeof weather.main != "undefined"
                ? weather.weather[0].main
                : ""}
            </div>
          </div>
          <div className="precipitation-box">
            <div className="precision-details">
              <div className="precision-box wind-box">
                <div className="precision-icon wind-color"></div>
                <div className="precision-value">
                  {typeof weather.main != "undefined"
                    ? `${Math.round(weather.wind.speed)} km/h`
                    : " km/h"}
                </div>
              </div>
              <div className="precision-box humidity-box">
                <div className="precision-icon humidity-color"></div>
                <div className="precision-value">
                  {typeof weather.main != "undefined"
                    ? `${Math.round(weather.main.humidity)} %`
                    : " %"}
                </div>
              </div>
              <div className="precision-box sunset-box">
                <div className="precision-icon sunset-color"></div>
                <div className="precision-value">
                  {typeof weather.main != "undefined"
                    ? sunsetBuilder(weather.sys.sunset, weather.timezone)
                    : ""}
                </div>
              </div>
            </div>
            <div className="temp">
              {typeof weather.main != "undefined"
                ? `${Math.round(weather.main.temp)} °c`
                : "°c"}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
